import { Component, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Offset } from '@progress/kendo-angular-popup';
import { DisclaimerService } from 'src/app/services/disclaimer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  public offset: Offset = { left: 100, top: 100};
  opened: Boolean = false;
  safeHtmlContent: SafeHtml = '';
  currentYear: number = new Date().getFullYear()

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    this.opened = false;
  }

  constructor (private disclaimerService: DisclaimerService, private sanitizer: DomSanitizer) { }

  disclaimerClick() {
     this.disclaimerService.getFooterDisclaimerHtml().subscribe(htmlContent => {
       this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
       this.opened = true;
     });
  }

  termsClick() {
     this.disclaimerService.getFooterTermsHtml().subscribe(htmlContent => {
       this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
       this.opened = true;
     });
  }
}
