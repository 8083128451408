import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { CrudActionResult } from '../model/crudActionResult';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationStateService } from './applicationState.service';
import { USER_PREFERENCES_INITIAL_STATE } from '../model/userAccount';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {

  constructor(
    private http: HttpClient,
    private stateService: ApplicationStateService,
    private router: Router
    ) {
      stateService.Initialise();
  }

  getDisclaimerHtml(): Observable<string> {
    return this.http.get(environment.apiUrl + '/v1/legal/popupDisclaimer', { responseType: 'text' });
  }

  getFooterDisclaimerHtml(): Observable<string> {
    return this.http.get(environment.apiUrl + '/v1/legal/footer-disclaimer', { responseType: 'text' });
  }

  getFooterTermsHtml(): Observable<string> {
    return this.http.get(environment.apiUrl + '/v1/legal/footer-terms', { responseType: 'text' });
  }

  accept() {
    return this.http.patch<CrudActionResult<number>>(environment.apiUrl + '/v1/useraccount/disclaimerAccepted', {})
      .subscribe(() => { 
        this.stateService.reloadPreferences().then(() => this.router.navigate(['search'])); // double check they were set on the server and update the preferences which are used in the guard
      });
  }

  decline() {
    window.location.href = "https://www.ajg.com";
  }
}

@Injectable({ providedIn: 'root' })
export class DisclaimerGuardService implements CanActivate {
  constructor(
    private router: Router, 
    private disclaimerService: DisclaimerService,
    private stateService: ApplicationStateService,
    
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.stateService.UserPreferences$.pipe(
      filter(x => x !== USER_PREFERENCES_INITIAL_STATE),
      map(x => {
        if(x.account.disclaimerAccepted) {
          return true;
        }
        else {
          // Redirect or handle the false case
          return this.router.createUrlTree(['/disclaimer-modal']);
        }
      })
    );
  }
}

