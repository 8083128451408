export const protectedResourceMap: [string, string[]][] =
[ /** Here we need to put all the authorised apis that will be called by our client, by doing so MSAL knows to send bearer tokens to the api/gateway/app it calls and it will be authorised to do so
 assuming that devops have told Azure AD that yes, this application has access to the api & the authentication lib app */
  ['https://graph.microsoft.com/v1.0/me/photo/$value', ['user.read']],

  // our API goes here, so we send the auth token to it.
  ['https://marketsecurityapidev.gallagherre.com', ['api://1a1fab2a-86ae-44d3-9702-57375565a1dc/GallagherRe-MarketSecurity-Api-Dev']],
  
  // AUTHORISATION API
  ['https://authorizationapidev.gallagherre.com/api/', ['api://35a3b1d2-e4fe-45f6-a8d2-f6e8c2fdc48f/user_impersonation']], //DEV
];

export const b2cProtectedResourceMap: [string, string[]][] = 
[  
  ['https://marketsecurityapidev.gallagherre.com', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']],
  
  // AUTHORISATION API
  ['https://authorizationapidev.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']]
];

export const environment = {
  environment: 'dev',
  production: false,  

  // APP SETTINGS
  applicationName: 'ajgre-market-security',
  apiUrl: 'https://marketsecurityapidev.gallagherre.com',

  //AUTH   
  cookieDomain: 'marketsecuritydev.gallagherre.com',
  clientId: '7381d2f6-3edb-4d18-8080-9b3581ab9e4a',
  authority: 'https://login.microsoftonline.com/6cacd170-f897-4b19-ac58-46a23307b80a',
  protectedResourceMap: protectedResourceMap,
  authorisationUrl: 'https://authorizationapidev.gallagherre.com/api/',
  redirectUri: 'https://marketsecuritydev.gallagherre.com/login', 
  postLogoutRedirectUri: 'https://marketsecuritydev.gallagherre.com/',
  rolesCacheSeconds: 60,
  authenticationCacheSeconds: 60,

  //B2C Config
  b2cClientId: '8d904b05-2518-4452-ae00-025aba666545',
  b2cAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_marketsecurity_si',
  b2cResetPasswordAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_marketsecurity_pass_reset',
  b2cKnownAuthorities: ['ajgb2c.b2clogin.com'],
  b2cCustomScopes: [],
  b2cProtectedResourceMap: b2cProtectedResourceMap,

  // 3rd PARTY CONFIG  
  appInsightsIntrumentationKey: 'e11dc9f1-6419-4717-82b9-b25ba76efef9',

  cookie: {
    enabled: true,
    scriptSource: '//policy.cookiereports.com/71679ccd_panel-en-gb.js',
    scriptDomainAttribute: '6e764445-61df-4556-af08-31294b7decd9-test',
    scriptHtml: '//policy.cookiereports.com/71679ccd-en-gb.html'
  },

  errorRetryCount: 2,
  errorRetryDelay: 500,

};