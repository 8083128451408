import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError, retry, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageService } from './message-service';
import { ApplicationInsightsService } from './application-insights.service';
import { Message, MessageType } from '../model/message';

const prodErrorString: string = `An error occurred in the Market Security Application. If this issue persists, please contact support.`;

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor { 
  
  constructor(
    private messageService: MessageService,
    private insightsService: ApplicationInsightsService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handle(next, req);
  }

  private handle(next: HttpHandler, req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry({ count: environment.errorRetryCount, delay: this.shouldRetry }),
      catchError((error: HttpErrorResponse) => {
        let message = environment.production ? prodErrorString : error.message;
          // Do nothing as this is out of our control due to a missing photo in users profile
        if (error.url?.startsWith('https://graph.microsoft.com/v1.0/me')) {
          return throwError(() => error);
        }
          
        // TODO: The following line looks wrong and needs to be investigated. This looks like a bodge to stop kendo client side errors.
        if (error.error instanceof ErrorEvent) {
          // This is client side error
        } else {
          //this is a server side error
          if (error.status == HttpStatusCode.BadRequest && error.error) {
            message = error.error[Object.keys(error.error)[0]];
          }
        }

        this.messageService.sendMessage(<Message>{
          heading: 'Error:',
          content: message,
          type: MessageType.Error
        });
        
        this.insightsService.logException(error);
        return throwError(() => error);
      })
    );
  }

  shouldRetry(error: HttpErrorResponse) {
    // All 4xx status codes we do not retry
    if((error.status !== undefined && error.status.toString().indexOf('4') === 0)){
      throw error;
    }

    // client side errors we do not retry
    if(error.error instanceof ErrorEvent){
      throw error;
    }

    return timer(environment.errorRetryDelay);
  }
}


