import { Component } from '@angular/core';
import { ApplicationStateService } from 'src/app/services/applicationState.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {
  public expanded: boolean = true;   

  constructor(
    private sidebarService: SidebarService,
    private appState: ApplicationStateService ) {
    
  }

  async ngOnInit() {
    this.sidebarService.sideBarExpanded$.subscribe(val => {
      this.expanded = val;
    });

    await this.appState.Initialise();
  }
}

