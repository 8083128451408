export enum EntityType{
    Carrier,
    MGA
}

export interface Entity {
    gallagherCode?:string;
    entityType: EntityType;
    id: number;
}

export const CARRIERENTITYTYPE = 'Carrier'
export const MGAENTITYTYPE = 'MGA'