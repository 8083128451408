import { Component, Input } from '@angular/core';
import { Carrier, Code, Rating } from 'src/app/model/carrier';
import { EntityType } from 'src/app/model/entity-types';
import { transformCodes } from 'src/app/shared/transformCodes'
@Component({
  selector: 'detail-summary',
  templateUrl: './detail-summary.component.html',
  styleUrls: ['./detail-summary.component.css']
})
export class DetailSummaryComponent {
  @Input() details: Carrier | any = [];
  @Input() codes: Code[] = [];
  @Input() ratings: Rating[] = [];
  @Input() entityType!: EntityType;
  entityTypeEnum= EntityType;

  goToLink(url: string) {
    window.open('http://' + url, '_blank');
  }
  transform(codeName:string):string{
   return transformCodes(codeName);
  }
}
