import { Component, OnInit, TemplateRef, isDevMode } from '@angular/core';
import { GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { ActionsLayout, DialogService } from '@progress/kendo-angular-dialog';
import { Message, MessageType } from 'src/app/model/message';
import { MessageService } from 'src/app/error-handling/message-service';
import { NotificationResponse, Notification, UiNotificationState } from 'src/app/model/notification';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ApplicationStateService } from 'src/app/services/applicationState.service';
import { NOTIFICATION_UNIVERSAL_OPTIONS, NOTIFICATION_INTERNAL_OPTIONS, DELETE_CONFIRMATION, DELETE_TITLE } from 'src/app/model/uiOptions';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CARRIERENTITYTYPE, Entity, EntityType, MGAENTITYTYPE } from 'src/app/model/entity-types';
import { Observable, Subject, delay } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-notifications',
  templateUrl: './manage-notifications.component.html',
  styleUrls: ['./manage-notifications.component.css']
})
export class ManageNotificationsComponent implements OnInit {
  notificationOptions: { text: string, value: number }[] = [];
  internalOptions: { text: string, value: number }[] = [
  ];
  universalOptions: { text: string, value: number }[] = [
  ];
  selectedNotificationOption: any = {};
  gridData: GridDataResult = { data: [], total: 0 };
  isExternal: boolean = false;
  isLoaderVisible: boolean = false;
  totalResults!: Notification[];
  selectedId!: number;
  selectedRow!: Notification;
  dialog: any;
  selectedRowIndex!: number;
  selectedNotificationId!: number;
  eventsSubject: Subject<string> = new Subject<string>();
  openNotificationSubject: Subject<any> = new Subject<any>();
  _entities: Entity[] = [];

  
  constructor(
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private appStateService: ApplicationStateService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.translateDropDownOptions();
    this.openNotificationSubject.pipe(delay(10)).subscribe(_ => this.eventsSubject.next('OPEN'));
  }

  ngOnInit(): void {
    this.appStateService.UserRoles$.subscribe(response => {
      this.isExternal = response.IsExternal();
      this.buildDropdownData(response.IsExternal())
      this.getNotifiications();
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateDropDownOptions();
      this.buildDropdownData(this.isExternal)
    });
  }

  translateDropDownOptions() {
    this.universalOptions = [];
    this.internalOptions = [];

    NOTIFICATION_UNIVERSAL_OPTIONS.map(x => {
      this.universalOptions.push({ text: this.translate.instant(x.text), value: x.value });
    });

    NOTIFICATION_INTERNAL_OPTIONS.map(x => {
      this.internalOptions.push({ text: this.translate.instant(x.text), value: x.value });
    });
  }

  buildDropdownData(isExternal: boolean): void {
    this.notificationOptions = [{ text: this.translate.instant('NOTIFICATION_OPTIONS.ALL'), value: 0 },
    ...(!isExternal ? this.internalOptions : []),
    ...this.universalOptions];
    this.selectedNotificationOption = this.notificationOptions[0];
  }

  getNotifiications() {
    this.isLoaderVisible = true;
    this.notificationService.getNotifications().subscribe(
      (response: NotificationResponse) => {
        this.isLoaderVisible = false;
        this.gridData = {
          data: response.results,
          total: response.totalResults,
        };
        this.totalResults = response.results;
        this.handleManageNotifications(this.selectedNotificationOption.value);
      },
      (error: any) => {
        // Handle the error
        this.isLoaderVisible = false;
        isDevMode() ? console.log(error) : false;
      }
    );
  }

  buildNotification(content: string, heading: string, messageType: MessageType) {
    const message = new Message(content, heading, messageType)
    return message;
  }

  deleteNotification() {
    const selectedOptionValue = this.selectedNotificationOption.value;
    this.isLoaderVisible = true;

    if (selectedOptionValue == 0) {
      //Delete all notifications for this entity
      this.notificationService.deleteNotification(this.selectedNotificationId).subscribe(x => {
        if (x.success) {
          this.showSuccessNotification()
          const toRemove = this.totalResults.findIndex(x => x.notificationId === this.selectedNotificationId)
          this.totalResults.splice(toRemove, 1);
          this.handleManageNotifications(selectedOptionValue);
        }
        this.close();
      })
    }
    else {
      //Remove a single selected notification type
      const state = <UiNotificationState>{
        watchCarrierDetails: selectedOptionValue == 2 ? false : null,
        watchCarrierRatings: selectedOptionValue == 4 ? false : null,
        watchApprovalStatus: selectedOptionValue == 1 ? false : null,
        watchDocuments: selectedOptionValue == 5 ? false : null,
        watchFinancialDetails: selectedOptionValue == 3 ? false : null,
      };
      const entities = {
        id: this.selectedId,
        entityType: this.selectedRow.entityType === CARRIERENTITYTYPE ? EntityType.Carrier : EntityType.MGA
      }

      this.notificationService.setNotificationsState([entities], state).subscribe(x => {
        if (x.success) {
          this.showSuccessNotification()
          this.updateItem(this.selectedNotificationId);
        }
        this.close();
      });

    }
  }

  showSuccessNotification() {
    const message = <Message>{
      type: MessageType.Success,
      content: this.translate.instant('NOTIFICATION.SAVENOTIFICATIONSUCCESS'),
      heading: this.translate.instant('NOTIFICATION.SAVENOTIFICATIONSUCCESS_HEADING')
    };
    this.messageService.sendMessage(message);
  }

  updateItem(notificationId: number) {
    if (!this.totalResults) {
      return;
    }

    const index = this.totalResults.findIndex(x => x.notificationId === notificationId)

    if (index < 0) {
      return;
    }

    if (this.selectedNotificationOption.value === 1) {
      this.totalResults[index].watchApprovalStatus = false;
    }
    else if (this.selectedNotificationOption.value === 2) {
      this.totalResults[index].watchCarrierDetails = false;
    }
    else if (this.selectedNotificationOption.value === 3) {
      this.totalResults[index].watchFinancialDetails = false;
    }
    else if (this.selectedNotificationOption.value === 4) {
      this.totalResults[index].watchCarrierRatings = false;
    }
    else if (this.selectedNotificationOption.value === 5) {
      this.totalResults[index].watchDocuments = false;
    }

    this.handleManageNotifications(this.selectedNotificationOption.value);
  }

  promptUserBeforeDeleting(actionTemplate: TemplateRef<unknown>, dataItem: Notification, rowIndex: number) {
    this.selectedNotificationId = dataItem.notificationId;
    this.selectedId = dataItem.carrierId ? dataItem.carrierId : dataItem.mgaId;
    this.selectedRow = dataItem;
    this.selectedRowIndex = rowIndex;
    let translatedDeletedTitle = this.translate.instant(DELETE_TITLE);
    let translatedDeletedConfirmation = this.translate.instant(DELETE_CONFIRMATION);
    this.dialog = this.dialogService.open({
      title: translatedDeletedTitle,
      content: translatedDeletedConfirmation,
      actions: actionTemplate,
      width: 400
    });
  }
 
  close() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  handleManageNotifications(value: number) {
    const dataToFilter = this.totalResults
    if (!dataToFilter || dataToFilter.length === 0) {
      return;
    }
    switch (value) {
      case 0: //All
        this.gridData.data = dataToFilter
        break;
      case 1: //Approvals
        this.gridData.data = dataToFilter.filter(x => x.watchApprovalStatus === true)
        break;
      case 2: //Carrier/Mga details
        this.gridData.data = dataToFilter.filter(x => x.watchCarrierDetails === true)
        break;
      case 3: //Financial details
        this.gridData.data = dataToFilter.filter(x => x.watchFinancialDetails === true)
        break;
      case 4: //Carrier rating
        this.gridData.data = dataToFilter.filter(x => x.watchCarrierRatings === true)
        break;
      case 5: //Documents
        this.gridData.data = dataToFilter.filter(x => x.watchDocuments === true)
        break;
      default:
        break;

    }
  }

  getRowClass(context: any): string {
    return `row-${context.index} remove-border`;
  }

  displayNotificationDialog(dataItem: Notification) {
     const entitySelected = <Entity>{
      entityType: dataItem.entityType === CARRIERENTITYTYPE ? EntityType.Carrier : EntityType.MGA,
      id: dataItem.entityType === CARRIERENTITYTYPE ? dataItem.carrierId : dataItem.mgaId,
      gallagherCode: dataItem.gallagherCode
    }
     this._entities = [entitySelected];

    this.openNotificationSubject.next(true);
  }

  reloadNotifications(eventData: any) {
    this.getNotifiications();
  }
  navigateToEntity(selectedRow: Notification) {
    if (selectedRow.entityType != null) {
      if (selectedRow.entityType.toLowerCase() === 'carrier') {
        this.router.navigate(['carrier-summary', selectedRow.carrierId]);
      } else {
        this.router.navigate(['mga-summary', selectedRow.mgaId]);
      }
    }
  }
}
