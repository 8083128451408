<header>
  <nav>
    <div class="header-container">

      <div class="headerText">
          <img src="../../../assets/images/Gallagher Re Market Security Guard_Horizontal_Large.png" width="368"
            />
      </div>
      <div class="actionsContainer float-end">
        <kendo-label [text]="('LANGUAGE.LANGUAGE' | translate) + ':'" id="la" class="languageLabel">
          <kendo-dropdownlist class="languages" [popupSettings]="{ popupClass: 'languagesPopup' }" size="small"
            [loading]="!currentUser" [data]="availableLanguages" textField="text" valueField="value"
            [defaultItem]="selectedLanguage" (selectionChange)="onLanguageSelect($event)" id="languageSelector">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <div class="languageItem" [id]="'languageItem-' + dataItem.value">
                <img *ngIf="dataItem.flagImage" class="langaugeItemImage" [src]="dataItem.flagImage"
                  [alt]="dataItem.text" />
                <span class="langaugeItemText">{{ dataItem.text }}</span>
              </div>
            </ng-template>
          </kendo-dropdownlist>
        </kendo-label>

        <div class="spacer"></div>

        <!-- placeholder dropdown to show that we are loading your profile  -->
        <kendo-dropdownlist *ngIf="!currentUser.friendlyName" class="userButtonLoading" size="small" [loading]="true"
          defaultItem="Loading" id="languageSelector">
        </kendo-dropdownlist>

        <!-- dropdown look-a-like for doing the funk styling wants -->
        <span *ngIf="currentUser.friendlyName">
          <kendo-button #anchor (click)="toggleUserInfo()" class="userButton">
            <div class="userButtonGrouping">
              <span class="userNameButtonText">{{currentUser.friendlyName}}</span>
              <kendo-icon *ngIf="showUserInfo" name="arrow-60-up"></kendo-icon>
              <kendo-icon *ngIf="!showUserInfo" name="arrow-60-down"></kendo-icon>
            </div>
          </kendo-button>
          <kendo-popup [anchor]="anchor.element" [copyAnchorStyles]="true" *ngIf="showUserInfo">
            <div class="popupContentUser">
              <span class="userName" id="userName">{{currentUser.friendlyName}}</span>
              <kendo-icon class="closeBtn" name="x" (click)="toggleUserInfo()"></kendo-icon>
              <br /><br />
              <span class="userEmail" id="userEmail">{{currentUser.email}}</span>
              <div class="signoutAligner">
                <kendo-button id="signoutButton" class="signoutButton" fillMode="solid" rounded="medium"
                  (click)="signout()">
                  {{ 'HEADER.SIGNOUT' | translate }}
                </kendo-button>
              </div>
            </div>
          </kendo-popup>
        </span>
      </div>
    </div>
  </nav>
</header>