import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/error-handling/message-service';
import { UI_LANGUAGE_LOADING, LanguageSelection, UI_LANGUAGES, UI_LANGUAGE_DEFAULT } from 'src/app/model/languages';
import { Message, MessageType } from 'src/app/model/message';
import { ApplicationStateService, USER_PROFILE_INITIAL_STATE } from 'src/app/services/applicationState.service';
import { SettingsService } from 'src/app/services/settings.service';
import { UserProfile } from 'wre-authlib';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  public currentUser: UserProfile = USER_PROFILE_INITIAL_STATE;
  public selectedLanguage = UI_LANGUAGE_LOADING;
  public showUserInfo = false;
  public languages = UI_LANGUAGES;
  public availableLanguages = UI_LANGUAGES;

  constructor(
    private stateService: ApplicationStateService,
    private settingsService: SettingsService,
    private messageService: MessageService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.stateService.UserProfile$.subscribe(x => {
      this.currentUser = x;
    });
    this.stateService.UserPreferences$.subscribe(x => {
      var languagePreference = x.language;
      if(languagePreference){
        var lang = UI_LANGUAGES.find(f => f.value == x.language);
        if(lang) {
          this.selectLanguage(lang);
          return;
        }
      }
      
      // use default language as can't get settings or find the setting they have
      this.selectLanguage(UI_LANGUAGE_DEFAULT); 
    });
  }

  onLanguageSelect(selected: LanguageSelection) {
    this.settingsService.setLanguage(selected.value).subscribe(x => {
        if(x.success) {       
          this.selectLanguage(selected); 
          
          this.translate.get(['LANGUAGE.NOW_SET_TO', 'LANGUAGE.PLEASE_REFRESH', 'LANGUAGE.UPDATED']).subscribe(tx => {
            this.messageService.sendMessage(<Message>{
              content: `${tx['LANGUAGE.NOW_SET_TO']} ${selected.text}. ${tx['LANGUAGE.PLEASE_REFRESH']}`,
              heading: tx['LANGUAGE.UPDATED'],
              type: MessageType.Success
            });            
          });   
        } else {
          this.translate.get(['LANGUAGE.NOT_UPDATED']).subscribe(tx => {            
            this.messageService.sendMessage(<Message>{
              content: x.message,
              heading: tx['LANGUAGE.NOT_UPDATED'],
              type: MessageType.Warning
            });
          });   
        }   
    });
  }
  selectLanguage (selected: LanguageSelection) {
    this.selectedLanguage = selected;
    this.translate.use(selected.value); 
    this.availableLanguages = this.languages.filter(x => x.value != selected.value)
  } 
  
  toggleUserInfo() {
    this.showUserInfo = !this.showUserInfo;
  }

  signout(){
    this.stateService.logout();
  }
}