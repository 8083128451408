export enum MessageType {
    Info = 0,
    Success = 1,
    Error = 2,
    Warning = 3
}

export class Message {
    constructor(content: string, heading: string, type: MessageType = MessageType.Error) {
        this.content = content;
        this.type = type;
        this.heading = heading;
    }

    readonly content: string;
    readonly heading: string;
    readonly type: MessageType;
};