import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent, PageNotFoundComponent } from 'wre-core-lib';
import { AuthGuardService, LoginComponent, PermissionGuardService, WreAuthSignoutComponent } from 'wre-authlib';
import { Roles } from './model/roles';
import { LayoutComponent } from './shared/layout/layout.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { ManageNotificationsComponent } from './components/notifications/manage-notifications/manage-notifications.component';
import { DisclaimerComponent } from './components/disclaimer-modal/disclaimer-modal.component';
import { DisclaimerGuardService } from './services/disclaimer.service';
import { AboutComponent } from './components/about/about.component';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'unauthorised', component: UnauthorizedComponent },
  { path: '', loadChildren: () => import('./shared/auth/user-type-selection/user-type-selection.module').then(m => m.UserTypeSelectionModule) },
  { path: 'signout', component: WreAuthSignoutComponent },
  { path: '', component: LayoutComponent, canActivate:[PermissionGuardService], 
  data: {
    permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
    redirectUrl: 'unauthorised'
  },
  children: [
    { path: 'login', component: LoginComponent, canActivate: [AuthGuardService] },
    {
      path: 'unauthorisedaccess', component: UnauthorizedComponent,
      data: { hideRequestAccessButton: true }
    },
    {
      path: 'search',
      loadChildren: () => import('./components/search/search.module').then((m) => m.SearchModule),
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'carrier-summary/:id',
      loadChildren: () => import('./components/carrier-summary/carrier-summary.module').then((m) => m.CarrierSummaryModule),
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'admin',
      loadChildren: () => import('./components/admin/admin.module').then((m) => m.AdminModule),
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'dashboard',
      component: PlaceholderComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'notifications',
      component: ManageNotificationsComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'reporting',
      component: PlaceholderComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'recent',
      component: PlaceholderComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'about',
      component: AboutComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'contact',
      component: PlaceholderComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'help',
      component: PlaceholderComponent,
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'mga-summary/:id',
      loadChildren: () => import('./components/mga-summary/mga-summary.module').then((m) => m.MgaSummaryModule),
      canActivate:[PermissionGuardService, DisclaimerGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },
    {
      path: 'disclaimer-modal',
      component: DisclaimerComponent,
      canActivate:[PermissionGuardService],
      data: {
        permissions: [Roles.admin, Roles.analyst, Roles.broker, Roles.client],
        redirectUrl: 'unauthorised'
      }
    },    
  ]},

  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
