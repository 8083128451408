import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import { MessageService } from 'src/app/error-handling/message-service';
import { Entity, EntityType } from 'src/app/model/entity-types';
import { Message, MessageType } from 'src/app/model/message';
import { UiNotificationState } from 'src/app/model/notification';
import { ApplicationStateService } from 'src/app/services/applicationState.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  dialogOpened = false;
  isExternal = true;

  userSetState: UiNotificationState | null = null;
  originalState: UiNotificationState | null = null;

  private _entities: Entity[] = [];
  @Input() get entities() {
    return this._entities;
  }
  set entities(e: Entity[]) {
    this._entities = e;
  }

  @Input() events: Observable<string> = of('');
  @Output() notifyLandingPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() maxHeight='400px';
  constructor(
    private messageService: MessageService, 
    private notificationService: NotificationsService,
    private applicationStateService: ApplicationStateService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
    ){

  }
  
  ngOnInit(){
    this.subscriptions.push(this.events.subscribe((e) => {
      this.handleEvent(e);
    }));
    this.subscriptions.push(
      this.applicationStateService.UserRoles$.subscribe(response => {
        this.isExternal = response.IsExternal();
      })
    );
  }

  ngOnDestroy() {
    this.cancel();
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }

  handleEvent(event: string) {
    const evt = event.toUpperCase();
    switch (evt) {
      case 'OPEN':
        this.dialogOpened = true;
        this.notificationService.getUiNotificationDialogState(this.entities).subscribe(x => {
          this.userSetState = x;         
          this.originalState = <UiNotificationState>{
            watchApprovalStatus: x.watchApprovalStatus,
            watchCarrierDetails: x.watchCarrierDetails,
            watchCarrierRatings: x.watchCarrierRatings,
            watchDocuments: x.watchDocuments,
            watchFinancialDetails: x.watchFinancialDetails,
          };
          this.changeDetectorRef.detectChanges(); 
        });
        
        break;
      case 'CLOSE':
        this.cancel();
        break;
      default:
        break;
    }
  }

  hasMgaEntities(): boolean {
    return this.entities.findIndex(x => x.entityType === EntityType.MGA) >= 0;
  }

  hasCarrierEntities(): boolean {
    return this.entities.findIndex(x => x.entityType === EntityType.Carrier) >= 0;
  }

  saveChanges() {
    if(!this.userSetState){
      return;
    }
    const state = <UiNotificationState>{
      watchCarrierDetails: this.userSetState.watchCarrierDetails,
      watchCarrierRatings: this.userSetState.watchCarrierRatings,
      watchDocuments: this.userSetState.watchDocuments,
      watchFinancialDetails: this.userSetState.watchFinancialDetails,
    }
    if(!this.isExternal){
      state.watchApprovalStatus = this.userSetState.watchApprovalStatus;
    }
    this.notificationService.setNotificationsState(this.entities, state).subscribe(x => {
      if(x.success) {
        this.translate.get(['NOTIFICATION.SAVENOTIFICATIONSUCCESS', 'NOTIFICATION.SAVENOTIFICATIONSUCCESS_HEADING']).subscribe(translations => {
          const message = <Message>{
            type: MessageType.Success,
            content: translations['NOTIFICATION.SAVENOTIFICATIONSUCCESS'],
            heading: translations['NOTIFICATION.SAVENOTIFICATIONSUCCESS_HEADING']
          };  
          this.notifyLandingPage.emit(true)
          this.messageService.sendMessage(message);
        });     
        
        this.cancel(); 
      }
    });
  }

  cancel() {
    this.userSetState = null;
    this.dialogOpened = false;
  }

  confirm() {
    this.saveChanges();
  }
}
