<div class="cont">
    <div class="about-header mb-3">
        <h2 class="header-title">About</h2>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="brand mb-3 mt-3">
                <img src="../../../assets/images/Gallagher_Re_Market_Security_Guard_Horizontal_Small_Trimmed.png" class="mb-3" width="378" alt="Market Security Guard Logo" />
            </div>
            <!--Translation required for the about text-->
            <h3 class="welcome-title">Welcome to Guard, the new Gallagher Re Market Security application</h3>
            <div class="welcome-intro">
                <p>Guard is a state-of-the-art platform that sets a new standard in delivering Market Security content. Supported by a team of experienced analysts, Guard offers meticulously curated financial data, credit rating material, and proprietary research, equipping our clients and colleagues with the necessary tools to make informed decisions.</p>
                <p>We are committed to data excellence and partner with global rating agencies such as AM Best, Fitch, and S&P to ensure the highest quality, speed and reliability of information provided.</p>
                <p>At Gallagher Re Market Security, we highly value client feedback and interaction. If you have any questions, suggestions, or feedback, please do not hesitate to reach out to us at <a href="mailto:gre_market_security@gallagherre.com" target="_blank" role="link" tabindex="0">gre_market_security@gallagherre.com</a>. We are dedicated to empowering our clients and providing them with the insights they need to navigate the increasingly complex world of reinsurance counterparty credit analysis.</p>
            </div>

            <div class="team">
                <h3 class="team-title">Guard Leadership Team</h3>
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-md-4 mb-sm-4">
                        <h4 class="team-member-name">Adrian Ling</h4>
                        <span class="team-member-role">Head of Gallagher Re Market Security</span>
                        <a href="mailto:adrian_ling@gallagherre.com" role="link" tabindex="0">Adrian_Ling@Gallagherre.com</a>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-md-4 mb-sm-4">
                        <h4 class="team-member-name">Nigel Willis</h4>
                        <span class="team-member-role">Chief Analyst</span>
                        <a href="mailto:nigel_willis@gallagherre.com" role="link" tabindex="0">Nigel_Willis@Gallagherre.com</a>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-md-4 mb-sm-4">
                        <h4 class="team-member-name">Angela D'Agnese</h4>
                        <span class="team-member-role">Operations Director</span>
                        <a href="mailto:angela_dagnese@gallagherre.com" role="link" tabindex="0">Angela_Dagnese@Gallagherre.com</a>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-md-0 mb-sm-4">
                        <h4 class="team-member-name">Shailendra Shetty</h4>
                        <span class="team-member-role">Manager</span>
                        <a href="mailto:shailendra_shetty@gallagherre.com" role="link" tabindex="0">Shailendra_Shetty@Gallagherre.com</a>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-md-0 mb-sm-0">
                        <h4 class="team-member-name">Sara Danso</h4>
                        <span class="team-member-role">Deputy Chief Analyst</span>
                        <a href="mailto:sara_danso@gallagherre.com" role="link" tabindex="0 ">Sara_Danso@Gallagherre.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>