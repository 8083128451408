import { UI_LANGUAGE_LOADING } from "./languages";

export interface UserPreferences {
    account: UserAccount;
    language: string;
} 

export interface UserAccount {
    userAccountId: number;
    email: string;
    language: string;
    disclaimerAccepted: Boolean;
}

export const USER_PREFERENCES_INITIAL_STATE : UserPreferences = <UserPreferences> {
    account: <UserAccount> { },
    language: UI_LANGUAGE_LOADING.value,
}