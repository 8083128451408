import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DisclaimerService } from 'src/app/services/disclaimer.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DisclaimerComponent implements OnInit {
  scrolledToBotton: Boolean = false;
  safeHtmlContent: SafeHtml = "";
  isAccepted: boolean = false;

  ngOnInit() {
    window.addEventListener('scroll', (event) => { this.onScroll(event); }, true);
  }

  constructor
    (
      private disclaimerService: DisclaimerService,
      private sanitizer: DomSanitizer
    ) {
    this.disclaimerService.getDisclaimerHtml().subscribe(htmlContent => {
      this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
    });
  }

  onScroll(event: any) {

    // Leaving this in place as advised the business may re-instate the scroll-to-bottom check and it
    // was tricky to get working. Re-instate 'scrolledToBotton' to the accept button enable logic on the HTML if requried.
    const scrollElement = event.srcElement?.parentElement?.parentElement;
    if (scrollElement?.id == "disclaimer") {
      let isAtBottom = (1 + event.srcElement.clientHeight + event.srcElement.scrollTop) >= event.srcElement.scrollHeight;
      if (isAtBottom && !this.scrolledToBotton) {
        this.scrolledToBotton = true;
      }
    }
  }

  decline() {
    this.disclaimerService.decline();
  }

  accept() {
    this.disclaimerService.accept();
  }

}
