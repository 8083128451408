import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CrudActionResult } from '../model/crudActionResult';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService  {

  constructor(private http: HttpClient) {
  }

  setLanguage(language:string): Observable<CrudActionResult<number>> {
    return this.http.put<CrudActionResult<number>>(environment.apiUrl + '/v1/useraccount/language/' + language, {});
  }
}
